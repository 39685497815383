import React from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import Icon from '../../primitives/Icon/index';
import styles from './Title.module.scss';

export type TitleProps = {
  text: string;
  icon?: IconProp;
  onClick: () => void;
  noWrap?: boolean;
};

const Title: React.FC<TitleProps> = ({icon, text, onClick, noWrap = false}) => (
  <div onClick={onClick} onKeyPress={onClick} role="button" tabIndex={0}>
    <h1
      className={`
        ${styles.text} 
        ${noWrap ? styles.noWrap : ''}
    `}
    >
      {icon ? (
        <span className={styles.icon}>
          <Icon icon={icon} />
        </span>
      ) : null}
      {text}
    </h1>
  </div>
);

export default Title;
