import React, {useState} from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import ReactDOM from 'react-dom';
import Modal from 'components/infra/Modal';
import Icon from '../../primitives/Icon';
import styles from './Card.module.scss';
import {UserRole} from '../../../constants';

export interface CardProps {
  title?: string;
  icon?: IconProp;
  dark?: boolean;
  special?: boolean;
  userRole?: UserRole;
  modalContent?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({
  children,
  title,
  icon,
  dark,
  special,
  userRole = 'anonymous',
  modalContent,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalChange = () => {
    setModalOpen(!modalOpen);
  };
  const rootElement = document.getElementById('root');

  return (
    <>
      <div
        className={`
        ${styles.card} 
        ${dark ? styles.dark : ''} 
        ${special ? styles.special : ''}
        ${styles[userRole]}
      `}
      >
        {!!modalContent && (
          <button
            className={styles.modalButton}
            title="descrição detalhada"
            onClick={handleModalChange}
          >
            i
          </button>
        )}
        {title && (
          <header title={title} className={styles.title}>
            {icon && <Icon icon={icon} />}
            {title}
          </header>
        )}
        <div className={styles.body}>{children}</div>
      </div>
      {rootElement &&
        !!modalContent &&
        ReactDOM.createPortal(
          <Modal
            isOpen={modalOpen}
            onClose={handleModalChange}
            clickOutsideToClose
          >
            {modalContent}
          </Modal>,
          rootElement,
        )}
    </>
  );
};

export default Card;
