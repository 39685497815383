import React from 'react';
import Icon from '../../primitives/Icon';
import Button, {Kind} from '../Button';

import {UserRole} from '../../../constants';

import styles from './Modal.module.scss';

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  buttonClose?: boolean;
  buttonFloatText?: string;
  onButtonFloatClick?: () => void;
  title?: string;
  isButtonFloatDisabled?: boolean;
  buttonKind?: Kind;
  userRole?: UserRole;
  vw?: number;
  vh?: number;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  clickOutsideToClose: boolean;
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  buttonClose = true,
  title,
  buttonFloatText,
  onButtonFloatClick,
  isButtonFloatDisabled = false,
  buttonKind = 'accentPrimary',
  userRole = 'student',
  children,
  clickOutsideToClose,
  vw,
  vh,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
}) => {
  const style: React.CSSProperties = {
    ...(vw ? {width: `${vw}vw`} : {}),
    ...(vh ? {height: `${vh}vh`} : {}),
    ...(minWidth ? {minWidth: `${minWidth}px`} : {}),
    ...(minHeight ? {minHeight: `${minHeight}px`} : {}),
    ...(maxWidth ? {maxWidth: `${maxWidth}px`} : {}),
    ...(maxHeight ? {maxHeight: `${maxHeight}px`} : {}),
  };

  const hasButtonFloat = buttonFloatText && onButtonFloatClick;

  return (
    <div
      data-testid="container"
      className={`${styles.container} ${isOpen ? styles.open : ''}`}
    >
      {isOpen && (
        <div className={`${styles.modal}`} role="dialog" style={style}>
          {buttonClose || title ? (
            <div
              className={`${styles.header} ${title ? styles.withTitle : ''} ${
                buttonClose ? styles.withButtonClose : ''
              }`}
            >
              {title && <h4 className={styles.title}>{title}</h4>}

              {buttonClose && (
                <button
                  className={styles.buttonClose}
                  onClick={onClose}
                  data-testid="buttonClose"
                >
                  <Icon icon="times" size="lg" color="#666" />
                </button>
              )}
            </div>
          ) : null}
          <div className={styles.content}>
            <div
              className={`${styles.children} ${
                hasButtonFloat ? styles.hasButtonFloat : ''
              }`}
            >
              {children}
            </div>
            {onButtonFloatClick && buttonFloatText && (
              <footer>
                <div className={styles.button}>
                  <Button
                    userRole={userRole}
                    kind={buttonKind}
                    text={buttonFloatText}
                    onClick={onButtonFloatClick}
                    disabled={isButtonFloatDisabled}
                  />
                </div>
              </footer>
            )}
          </div>
        </div>
      )}
      <div
        data-testid="background"
        className={styles.background}
        onMouseDown={clickOutsideToClose ? onClose : undefined}
        role="presentation"
      />
    </div>
  );
};

export default Modal;
