import React, {ReactElement, useState, KeyboardEvent} from 'react';

import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import styles from './StarRating.module.scss';
import Icon from '../../primitives/Icon';
import TitleText from '../TitleText';
import Button from '../Button';

export interface StarRatingProps {
  readonly amountOfStars: number;
  activeStars: number;
  title?: string;
  subtitle?: string;
  onClick(rate: number): void;
  size?: SizeProp;
  smallerRatingLabel?: string;
  biggerRatingLabel?: string;
  submitText?: string;
  isButtonDisabled?: boolean;
  onSubmit?(): void;
  onGoBack?(): void;
}

export enum StarTypes {
  selected = 'SELECTED',
  hovered = 'HOVERED',
  declined = 'DECLINED',
}

export type StarState = boolean;

type StarsState = Array<StarState>;

const StarRating: React.FC<StarRatingProps> = ({
  amountOfStars,
  activeStars,
  title,
  subtitle,
  onClick,
  size = '1x',
  submitText,
  smallerRatingLabel,
  biggerRatingLabel,
  onSubmit,
  onGoBack,
  isButtonDisabled = false,
}) => {
  const [isHovered, setIsHovered] = useState<StarsState>(
    [...Array(amountOfStars).keys()].map(() => false),
  );

  const getStarType = (index: number): StarTypes => {
    if (index < activeStars) return StarTypes.selected;
    if (isHovered[index]) return StarTypes.hovered;
    return StarTypes.declined;
  };

  const onMouseOver = (hoveredIndex: number): void => {
    const hasStarHovered = hoveredIndex > activeStars - 1;
    if (!hasStarHovered) return;

    const newStarsType = [...Array(amountOfStars).keys()].map(
      (index): StarState => {
        return index <= hoveredIndex;
      },
    );
    setIsHovered(newStarsType);
  };

  const handleStarClick = (index: number): void => {
    onClick(index + 1);
  };

  const onMouseLeave = (): void => {
    const starTypesWithoutHover: StarsState = isHovered.map(
      (): StarState => false,
    );
    setIsHovered(starTypesWithoutHover);
  };
  const allStars = (): Array<ReactElement> => {
    return [...Array(amountOfStars).keys()].map(
      (index: number): ReactElement => (
        <span
          key={`star-${index}`}
          onKeyPress={(e: KeyboardEvent): void | false => {
            return e.key === 'Enter' && handleStarClick(index);
          }}
          role="button"
          tabIndex={index + 1}
          className={`${styles.star} ${styles[getStarType(index)]}`}
          onMouseEnter={(): void => onMouseOver(index)}
          onMouseLeave={onMouseLeave}
          onClick={(): void => handleStarClick(index)}
        >
          <Icon size={size} icon="star" />
        </span>
      ),
    );
  };

  return (
    <div className={styles.wrapper}>
      {title && <TitleText tag="h1" title={title} />}
      {subtitle && (
        <div
          className={styles.subtitle}
          dangerouslySetInnerHTML={{__html: subtitle}}
        />
      )}
      <div className={styles.starsWrapper}>
        {allStars()}
        <div className={styles.bottom}>
          {smallerRatingLabel && <span>{smallerRatingLabel}</span>}
          {biggerRatingLabel && <span>{biggerRatingLabel}</span>}
        </div>
      </div>
      <div className={styles.wrapperSubmit}>
        {onGoBack && (
          <Button userRole="student" kind="secondary" onClick={onGoBack}>
            Voltar
          </Button>
        )}
        {onSubmit && (
          <Button
            userRole="student"
            onClick={onSubmit}
            disabled={isButtonDisabled}
          >
            {submitText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default StarRating;
