import Icon from 'components/primitives/Icon';
import React from 'react';

import styles from './Pagination.module.scss';

export interface PaginationProps {
  totalPages: number;
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;

  onChange: (number: number) => void;
  onClickPreviousBack?: () => void;
  onClickPrevious?: () => void;
  onClickNext?: () => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  hasNext,
  hasPrevious,
  onChange,
  onClickPreviousBack,
  onClickPrevious,
  onClickNext,
}) => {
  const handleClickPrevious = (): void => {
    onChange(currentPage - 1);
    if (onClickPrevious) onClickPrevious();
  };
  const handleClickPreviousBack = (): void => {
    onChange(currentPage - 2);
    if (onClickPreviousBack) onClickPreviousBack();
  };

  const handleClickNext = (): void => {
    onChange(currentPage + 1);
    if (onClickNext) onClickNext();
  };

  const handleClickFinalPage = (): void => {
    onChange(totalPages);
  };

  const isLastPage = currentPage === totalPages;
  const isFirstPage = currentPage === 1;

  return (
    <div className={styles.wrapper}>
      <button
        className={currentPage === 1 ? styles.changeDisabled : ''}
        aria-disabled={!hasPrevious}
        title="Anterior"
        data-testid="previous"
        disabled={!hasPrevious}
        onClick={handleClickPrevious}
      >
        <Icon icon="chevron-left" />
      </button>

      {currentPage && isLastPage && !isFirstPage && totalPages > 2 && (
        <button
          data-testid="aroundPreviousBack"
          onClick={handleClickPreviousBack}
        >
          {currentPage > 1 && currentPage - 2}
        </button>
      )}

      {currentPage && !isFirstPage && hasPrevious && (
        <button data-testid="aroundPrevious" onClick={handleClickPrevious}>
          {currentPage > 1 && currentPage - 1}
        </button>
      )}

      <button data-testid="currentPage" className={styles.current}>
        {currentPage}
      </button>

      {currentPage && !isLastPage && hasNext && (
        <>
          {currentPage + 1 !== totalPages && (
            <button
              data-testid="aroundNext"
              disabled={!hasNext}
              onClick={handleClickNext}
            >
              {totalPages > currentPage && currentPage + 1}
            </button>
          )}
          <button data-testid="finalPage" onClick={handleClickFinalPage}>
            {totalPages}
          </button>
        </>
      )}

      <button
        className={isLastPage ? styles.changeDisabled : ''}
        title="Próximo"
        disabled={!hasNext}
        data-testid="next"
        onClick={handleClickNext}
      >
        <Icon icon="chevron-right" />
      </button>
    </div>
  );
};

export default Pagination;
