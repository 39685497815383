import React from 'react';
import Icon from '../../primitives/Icon/index';

import styles from './TitleText.module.scss';

export type Tag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

export interface TitleTextProps {
  icon?: any;
  tag: Tag;
  title: string;
}

const TitleText: React.FC<TitleTextProps> = ({icon, tag: Tag, title}) => (
  <div>
    <Tag className={styles.title}>
      {icon ? (
        <span className={styles.icon}>
          <Icon icon={icon} />
        </span>
      ) : null}
      {title}
    </Tag>
  </div>
);

export default TitleText;
