import React, {useState} from 'react';
import {UserRole} from '../../../constants';
import Button from '../../infra/Button';
import styles from './NPSInput.module.scss';

type NPSInputProps = {
  title?: string;
  description?: string;
  step?: number;
  userRole?: UserRole;
  buttonText?: string;
  onChange: (value: number) => void;
  onSubmit: () => void;
};

const NPSInput: React.FC<NPSInputProps> = ({
  title,
  description,
  step,
  userRole = 'anonymous',
  buttonText,
  onChange,
  onSubmit,
}) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null,
  );

  function handleChange(value: number): void {
    setSelectedItemIndex(value);
    onChange(value);
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{title}</h1>

      {description && <p className={styles.description}>{description}</p>}

      <div className={styles.container}>
        <div className={styles.inputContainer}>
          {[...Array(11).keys()].map(index => {
            return (
              <input
                key={index}
                className={`${styles[`button-${index}`]} ${
                  selectedItemIndex === index ? styles[`--selected`] : ''
                }`}
                data-testid={`button-${index}`}
                type="button"
                step={step}
                value={index}
                onClick={(): void => {
                  handleChange(index);
                }}
              />
            );
          })}
        </div>
        {buttonText && (
          <Button userRole={userRole} onClick={onSubmit} size="large">
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default NPSInput;
