import React from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import Icon from '../../primitives/Icon';

import styles from './NewButton.module.scss';
import {UserRole} from '../../../constants';

export type Kind = 'primary' | 'secondary';

export type Size = 'small' | 'regular';

export type Type = 'button' | 'submit' | 'reset';

export interface NewButtonProps {
  text?: string;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  icon?: IconProp;
  type?: Type;
  kind?: Kind;
  size?: Size;
  userRole?: UserRole;
  outline?: boolean;
}

const NewButton: React.FC<NewButtonProps> = ({
  text,
  children,
  onClick,
  icon,
  isLoading = false,
  disabled = false,
  kind = 'primary',
  type = 'button',
  size = 'small',
  userRole = 'anonymous',
  outline = false,
}) => (
  <button
    data-testid="defaultButton"
    className={`${styles.NewButton}        
        ${disabled ? styles.disabled : styles[kind]}
        ${isLoading ? styles.isLoading : styles[kind]}
        ${styles[userRole]}
        ${text || children || isLoading ? styles[size] : styles.onlyIcon}
        ${outline ? styles.outline : ''}
        `}
    onClick={onClick}
    type={type}
    disabled={disabled}
  >
    {isLoading ? (
      <div>
        <div
          className={`${
            size === 'small' ? styles.circleSmall : styles.circleRegular
          } ${styles.circle1} ${
            userRole === 'student' && kind === 'primary'
              ? styles.circleWhite
              : styles.circleDark
          }`}
        />
        <div
          className={`${
            size === 'small' ? styles.circleSmall : styles.circleRegular
          } ${styles.circle2} ${
            userRole === 'student' && kind === 'primary'
              ? styles.circleWhite
              : styles.circleDark
          }`}
        />
        <div
          className={`${
            size === 'small' ? styles.circleSmall : styles.circleRegular
          } ${styles.circle3} ${
            userRole === 'student' && kind === 'primary'
              ? styles.circleWhite
              : styles.circleDark
          }`}
        />
      </div>
    ) : (
      <>
        {icon && (
          <div className={`${text || children ? styles.newButtonText : ''}`}>
            <Icon icon={icon} />
          </div>
        )}
        {text || children}
      </>
    )}
  </button>
);

export default NewButton;
