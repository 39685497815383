import React, {InputHTMLAttributes, LegacyRef, RefCallback} from 'react';
import styles from './Checkbox.module.scss';

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  labelText: string;
  ref?: RefCallback<HTMLInputElement> | LegacyRef<HTMLInputElement>;
}

const Checkbox: React.FC<CheckboxProps> = ({labelText, id, ...otherProps}) => {
  return (
    <div className={styles.inputContainer}>
      <input id={id} type="checkbox" {...otherProps} />

      {labelText && <label htmlFor={id}>{labelText}</label>}
    </div>
  );
};

export default Checkbox;
