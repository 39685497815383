import React from 'react';

import Icon, {IconProps} from '../../primitives/Icon';
import styles from './RoundedButton.module.scss';
import {UserRole} from '../../../constants';

export type Kind =
  | 'primary'
  | 'secondary'
  | 'accentPrimary'
  | 'accentSecondary'
  | 'negativePrimary'
  | 'negativeSecondary'
  | 'warning'
  | 'success'
  | 'danger';

export type RoundedButtonProps = {
  userRole?: UserRole;
  kind?: Kind;
  text?: string;
  icon: IconProps;
  disabled?: boolean;
  isLoading?: boolean;
  onClick: React.MouseEventHandler;
  size?: 'small' | 'medium' | 'large';
  type?: 'button' | 'submit' | 'reset';
  active?: boolean;
  visibleText?: boolean;
};

const RoundedButton: React.FC<RoundedButtonProps> = ({
  text,
  visibleText = false,
  icon,
  disabled = false,
  kind = 'primary',
  isLoading = false,
  onClick,
  size = 'small',
  type = 'button',
  active = false,
  userRole = 'anonymous',
}) => {
  return (
    <>
      {text ? (
        <div className={styles.wrapperButton}>
          <button
            data-testid="roundedButton"
            className={`
          ${styles[size]}
          ${styles.content}
          ${isLoading || disabled ? styles.disabled : styles[kind]}
          ${styles[userRole]}
          ${active ? styles.active : ''}
        `}
            type={type}
            onClick={onClick}
            disabled={isLoading || disabled}
          >
            <Icon {...icon} />
          </button>
          <span
            className={`${styles.text} ${visibleText ? styles.visible : ''}`}
          >
            {text}
          </span>
        </div>
      ) : (
        <button
          data-testid="roundedButton"
          className={`
        ${styles[size]}
        ${styles.content}
        ${isLoading || disabled ? styles.disabled : styles[kind]}
        ${styles[userRole]}
        ${active ? styles.active : ''}
      `}
          type={type}
          onClick={onClick}
          disabled={isLoading || disabled}
        >
          <Icon {...icon} />
        </button>
      )}
    </>
  );
};

export default RoundedButton;
