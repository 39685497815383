import Icon from 'components/primitives/Icon';
import React, {InputHTMLAttributes, LegacyRef, RefCallback} from 'react';
import styles from './InputPassword.module.scss';

export interface InputPasswordProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'ref'> {
  labelText?: string;
  showPassword?: boolean;
  onClickPasswordIcon?(): void;
  errorMessage?: string;
  inputRef?: RefCallback<HTMLInputElement> | LegacyRef<HTMLInputElement>;
}

const InputPassword: React.FC<InputPasswordProps> = ({
  id,
  labelText = '',
  showPassword = false,
  onClickPasswordIcon,
  errorMessage = '',
  inputRef,
  ...otherProps
}) => {
  return (
    <>
      {!!labelText && <label htmlFor={id}>{labelText}</label>}

      <div className={styles.inputContainer}>
        <input
          id={id}
          type={showPassword ? 'text' : 'password'}
          className={`${errorMessage ? styles.error : ''}`}
          ref={inputRef}
          {...otherProps}
        />

        <div
          onClick={onClickPasswordIcon}
          className={styles.showPasswordIconWrapper}
          role="presentation"
        >
          <Icon
            icon={['fas', showPassword ? 'eye' : 'eye-slash']}
            color="#95989a"
          />
        </div>
      </div>

      {!!errorMessage && (
        <label htmlFor={id} className={styles.errorLabel}>
          {errorMessage}
        </label>
      )}
    </>
  );
};

export default InputPassword;
