import React from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import Icon from '../../primitives/Icon';

import styles from './Input.module.scss';

export interface InputProps {
  placeholder: string;
  disabled?: boolean;
  inputTag?: string;
  type?: string;
  rows?: number;
  cols?: number;
  maxlength?: number;
  minlength?: number;
  required?: boolean;
  autofocus?: boolean;
}

export interface InputTextProps {
  id: string;
  label: string;
  input: {
    name: string;
    onChange?: (eventOrValue: React.SyntheticEvent | string) => void;

    value: string;
  };
  meta: {
    touched: boolean;
    error: string;
  };
  inputProps: InputProps;
  icon?: IconProp;
  options?: Array<{value: number | string; label: string}>;
}

const Input: React.FC<InputTextProps> = ({
  id,
  label,
  input,
  meta,
  icon,
  options,
  inputProps,
}) => {
  const Input = inputProps.inputTag as 'input' | 'select' | 'textarea';
  const {inputTag, ...props} = inputProps;
  const isIconVisble =
    icon && inputTag === 'input' && inputProps.type === 'text';
  const isSelectInput = options && inputTag === 'select';
  return (
    <div className={styles.inputWrapper}>
      {label && (
        <label
          className={styles.label}
          htmlFor={id || `inputText-${input.name}`}
        >
          {label}
        </label>
      )}
      <Input
        className={`${meta?.touched && meta.error ? styles.error : ''}${
          inputProps.disabled ? styles.disabled : ''
        }
        ${isIconVisble ? styles.icon : ''}
        `}
        {...input}
        {...props}
        id={id || `inputText-${input?.name}`}
      >
        {isSelectInput
          ? options &&
            options.map(({label, value}) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))
          : null}
      </Input>
      {isIconVisble && <Icon icon={icon as IconProp} />}
      {meta?.touched && meta?.error && (
        <span className={styles.errorMessage}>{meta?.error}</span>
      )}
    </div>
  );
};

export default Input;
