import React, {useState} from 'react';

import styles from './ProgressBar.module.scss';
import Icon from '../../primitives/Icon';

export interface ProgressBarProps {
  label?: string;
  min: number;
  goal: number;
  max: number;
  value: number;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

enum Colors {
  red = 'red',
  yellow = 'yellow',
  green = 'green',
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  max,
  min,
  goal,
  value,
  label,
  children,
  onClick,
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  // The size of the colored bar is the distance from 0 to the 'value'
  // The size of the bar between 'value' and 'goal' is' goal' minus 'value',
  // if the value is less than the' goal ', otherwise it is 0
  const getBetweenSpace = (): number => {
    if (goal > 0) {
      return value < goal ? goal - value : 0;
    }

    return value < 10 ? value : 10;
  };
  const between = getBetweenSpace();
  // The size of the 'rest bar' is the 'max' which can reach minus the value of the colored bar plus the bar between value and goal,
  // if 'max' is greater than the value of the colored bar plus the bar between value and goal, if not, is 0
  const getHiddenSpace = (): number => {
    if (goal > 0) {
      return value + between < max ? max - (value + between) : 0;
    }

    return 0;
  };
  const hiddenSpace = getHiddenSpace();
  // The size of the progressSize is the sum of the distance of the color bar, bar between value and goal and rest
  const progressSize = goal > 0 ? value + between + hiddenSpace : 10;
  const progressValue = goal > 0 ? value : 10 - between;

  const getColor = (): Colors => {
    /**
     * The score is variation of 0 to 1.
     * It is calculated in relation to min and max range, considering that
     * the goal is correspond to 1 and the min and max to 0.6
     */

    // Limit is the variable that returns the 'maximum' value if the 'value' has passed the 'goal',
    // if it has not passed then the 'min' is returned
    const limit = value > goal ? max : min;
    // If the 'value' is equal to the 'goal', then it returns 1 (which is green)
    // Otherwise, he takes 41% of the limit minus the 'goal' and divides it by the 'value' minus the 'goal'
    const score =
      value === goal ? 1 : ((limit - goal) * 0.1659) / (value - goal);

    // If the 'score' is less to 61% then bar is red, or
    // If the 'score' is less to 1% and up to 61% then bar is yellow, or
    // If the 'score' is less to 1% then bar is green

    // score = ((0% - 100%) * 0.1659) / (21% - 100%) = 0.21
    if (score < 0.21) {
      return Colors.red;
    }

    // score = ((0% - 100%) * 0.1659) / (61% - 100%) = 0.425
    if (score < 0.425) {
      return Colors.yellow;
    }

    return Colors.green;
  };

  const handleClickTitle: React.MouseEventHandler<HTMLButtonElement> = event => {
    setIsDetailsOpen(!isDetailsOpen);

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div className={styles.ProgressBar}>
      {label ? (
        <div className={styles.label}>
          <div>
            {children ? (
              <button onClick={handleClickTitle}>
                {isDetailsOpen ? (
                  <Icon icon={['fal', 'minus-circle']} />
                ) : (
                  <Icon icon={['fal', 'plus-circle']} />
                )}
                {label}
              </button>
            ) : (
              label
            )}
          </div>
          <div>
            {value}/<span className={styles.goal}>{goal}</span>
          </div>
        </div>
      ) : null}
      <div className={styles.component}>
        <div
          className={styles.progress}
          style={{flex: progressValue / progressSize, minWidth: 12}}
        >
          <div className={`${styles.bar} ${styles[getColor()]}`} />
          <div className={`${styles.ball} ${styles.ballActive}`} />
        </div>
        {between > 0 ? (
          <div
            className={styles.progress}
            style={{flex: between / progressSize}}
          >
            <div className={`${styles.bar} ${styles.between}`} />
            <div className={styles.ball} />
          </div>
        ) : null}
        <div
          className={styles.progress}
          style={{flex: hiddenSpace / progressSize}}
        >
          <div className={`${styles.bar} ${styles.last}`} />
        </div>
      </div>
      {children && isDetailsOpen && <div>{children}</div>}
    </div>
  );
};

export default ProgressBar;
